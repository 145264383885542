$color: #4664f4; // 默认主题颜色
$c_000: #000;
$c_666: #666;
$c_999: #999;
$c_fff: #fff;
$c_f00: #f00;
.color {color: $color}
.c_000 {color: $c_000}
.c_666 {color: $c_666}
.c_999 {color: $c_999}
.c_fff {color: $c_fff}
.c_f00 {color: $c_f00}
.c_30aa44 {color: #30aa44}
